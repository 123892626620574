import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-437a9543"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "product-name"
};
var _hoisted_2 = {
  class: "tagline"
};
var _hoisted_3 = {
  class: "flex-col",
  style: {
    "flex": "0 0 auto"
  }
};
var _hoisted_4 = {
  class: "flex-col",
  style: {
    "flex": "0 1 100%",
    "white-space": "normal"
  }
};
var _hoisted_5 = {
  class: "flex-col",
  style: {
    "flex": "0 0 auto"
  }
};
var _hoisted_6 = {
  class: "flex-col",
  style: {
    "flex": "0 1 100%",
    "white-space": "normal"
  }
};
var _hoisted_7 = {
  class: "flex-col",
  style: {
    "flex": "0 0 auto"
  }
};
var _hoisted_8 = {
  class: "flex-col",
  style: {
    "flex": "0 1 100%",
    "white-space": "normal"
  }
};
import { Right } from '@element-plus/icons-vue';
import CarouselSlide from '@/components/CarouselBanner/CarouselSlide.vue';
export default {
  props: {
    slug: String,
    productName: String,
    tagline: String,
    src: String,
    features: Array
  },
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_ImageWithAlt = _resolveComponent("ImageWithAlt");

      var _component_router_link = _resolveComponent("router-link");

      var _component_el_col = _resolveComponent("el-col");

      var _component_el_icon = _resolveComponent("el-icon");

      var _component_el_row = _resolveComponent("el-row");

      var _component_el_container = _resolveComponent("el-container");

      return _openBlock(), _createBlock(CarouselSlide, {
        name: __props.slug
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_el_container, {
            direction: "vertical",
            style: {
              "height": "100%"
            }
          }, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                justify: "center",
                class: "product-features-card"
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, {
                    class: "image-container flex-centered",
                    sm: 12
                  }, {
                    default: _withCtx(function () {
                      return [_createVNode(_component_router_link, {
                        to: {
                          name: 'Auto-Tune',
                          params: {
                            slug: __props.slug
                          }
                        }
                      }, {
                        default: _withCtx(function () {
                          return [_createVNode(_component_ImageWithAlt, {
                            src: __props.src,
                            fit: "contain",
                            class: "image"
                          }, null, 8, ["src"])];
                        }),
                        _: 1
                      }, 8, ["to"])];
                    }),
                    _: 1
                  }), _createVNode(_component_el_col, {
                    class: "flex-col product-details",
                    style: {
                      "justify-content": "center"
                    },
                    sm: 12
                  }, {
                    default: _withCtx(function () {
                      return [_createElementVNode("h3", _hoisted_1, _toDisplayString(__props.productName), 1), _createElementVNode("span", _hoisted_2, _toDisplayString(__props.tagline), 1), _createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.features, function (v) {
                        return _openBlock(), _createElementBlock("li", {
                          key: v
                        }, [typeof v === 'string' ? (_openBlock(), _createBlock(_component_el_row, {
                          key: 0,
                          class: "std-padding_line-sm",
                          style: {
                            "flex-wrap": "nowrap"
                          }
                        }, {
                          default: _withCtx(function () {
                            return [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_icon, null, {
                              default: _withCtx(function () {
                                return [_createVNode(_unref(Right))];
                              }),
                              _: 1
                            })]), _createElementVNode("div", _hoisted_4, _toDisplayString(v), 1)];
                          }),
                          _: 2
                        }, 1024)) : (_openBlock(), _createBlock(_component_el_row, {
                          key: 1
                        }, {
                          default: _withCtx(function () {
                            return [_createVNode(_component_el_col, null, {
                              default: _withCtx(function () {
                                return [_createVNode(_component_el_row, {
                                  class: "std-padding_line-sm",
                                  style: {
                                    "flex-wrap": "nowrap"
                                  }
                                }, {
                                  default: _withCtx(function () {
                                    return [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_icon, null, {
                                      default: _withCtx(function () {
                                        return [_createVNode(_unref(Right))];
                                      }),
                                      _: 1
                                    })]), _createElementVNode("div", _hoisted_6, _toDisplayString(v.main_point), 1)];
                                  }),
                                  _: 2
                                }, 1024), _createVNode(_component_el_row, null, {
                                  default: _withCtx(function () {
                                    return [_createElementVNode("ul", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(v.sub_points, function (sub_point) {
                                      return _openBlock(), _createElementBlock("li", {
                                        key: sub_point
                                      }, [_createVNode(_component_el_row, {
                                        class: "std-padding_line-sm",
                                        style: {
                                          "flex-wrap": "nowrap"
                                        }
                                      }, {
                                        default: _withCtx(function () {
                                          return [_createElementVNode("div", _hoisted_7, [_createVNode(_component_el_icon, null, {
                                            default: _withCtx(function () {
                                              return [_createVNode(_unref(Right))];
                                            }),
                                            _: 1
                                          })]), _createElementVNode("div", _hoisted_8, _toDisplayString(sub_point), 1)];
                                        }),
                                        _: 2
                                      }, 1024)]);
                                    }), 128))])];
                                  }),
                                  _: 2
                                }, 1024)];
                              }),
                              _: 2
                            }, 1024)];
                          }),
                          _: 2
                        }, 1024))]);
                      }), 128))])];
                    }),
                    _: 1
                  })];
                }),
                _: 1
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["name"]);
    };
  }
};